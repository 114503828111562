<template>
<v-col>

  <v-row>
    <v-col cols="12" lg="12" md="12">

       <!--Titre statistque au centre-->
       <h2 class="text-center">Finances</h2>

       <v-row>

        <v-col cols="5" lg="2">
          <v-menu v-model="menu" :close-on-content-click="false">
            <v-text-field
              :value="date_debut"
              slot="activator"
              v-model="date_debut"
              prepend-icon="mdi-calendar"
              clearable label="Date debut"
            ></v-text-field>
              <v-v-date-picker v-model="date_debut" @change="menu = false" ></v-v-date-picker>
          </v-menu>

        </v-col>
        <v-col cols="5" lg="2">
          <v-menu>
            <v-text-field
              :value="date_fin"
              slot="activator"
              v-model="date_fin"
              prepend-icon="mdi-calendar"
              label="Date fin"
            ></v-text-field>
              <v-v-date-picker v-model="date_fin" ></v-v-date-picker>
          </v-menu>

        </v-col>
        <v-col>
            <v-btn color="primary" dark class="mb-2" @click="readRecettes">
                Recherche
            </v-btn>

        </v-col>

      </v-row>
      <!-- if  connected_profil is true show list else show on card-->
      <v-row v-if="!connected_profil">

        <v-col cols="12" md="4">
          <base-card color="#34495E">
          <v-card-text class="d-flex align-center h-full">
            <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
              <!-- Row with title and value -->
              <v-col>
                <v-row>
                          <p class="ma-0 white--text text-center">
                            Commission :
                          </p>
                          <p class="white--text ma-0 text-center">
                            {{ arrondi ((recettes.recette/2),2) }}
                          </p>
                </v-row>

                <v-row>
                          <p class="ma-0 white--text text-center">
                              Depenses :
                          </p>
                          <div class="white--text ma-0 text-center">
                            {{ arrondi ((recettes.depense),2) }}
                          </div>
                </v-row>

                <v-row>
                          <p class="ma-0 white--text text-center">
                              Commission parrain :
                          </p>
                          <div class="white--text ma-0 text-center">
                            {{ arrondi ((((recettes.recette/2) - recettes.depense) * 0.1),2)  }}
                          </div>
                </v-row>

                <v-row>
                          <p class="ma-0 white--text text-center">
                              Solde
                          </p>
                          <div class="white--text ma-0 text-center">
                            {{ arrondi ((recettes.recette  - (( ((recettes.recette/2) - recettes.depense) * 0.1) + recettes.depense + (recettes.recette/2) )),2) }}
                          </div>
                </v-row>
            </v-col>

            </div>
          </v-card-text>
        </base-card>
        </v-col>

      </v-row>

      <v-row v-if="connected_profil">

        <v-col cols="12" md="4" v-for="(recettes_table,
                                                index) in recettes_table" :key="index">
                <!-- test -->
                <base-card color="#34495E">
                    <v-card-text class="d-flex align-center h-full">
                        <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
                          <v-col>
                            <h3 class="text-center white--text">{{recettes_table.pays}}</h3>
                            <v-row>
                              <p class="ma-0 white--text text-center">
                                  Commission :
                              </p>
                              <div class="white--text ma-0 text-center">
                                {{ recettes_table.pays == 'BENIN' ? arrondi((recettes_table.recette),2) : arrondi((recettes_table.recette/2),2) }}
                              </div>
                          </v-row>

                          <v-row>
                              <p class="ma-0 white--text text-center">
                                  Depenses :
                              </p>
                              <div class="white--text ma-0 text-center">
                                {{ arrondi((recettes_table.depense),2) }}
                              </div>
                          </v-row>



                          <v-row>
                                    <p class="ma-0 white--text text-center">
                                        Commission parrain :
                                    </p>
                                    <div class="white--text ma-0 text-center">
                                      {{ recettes_table.pays == 'BENIN' ? arrondi(((recettes_table.recette - recettes_table.depense) * 0.1),2) : arrondi((((recettes_table.recette/2) - recettes_table.depense) * 0.1),2)  }}
                                    </div>
                          </v-row>

                          <v-row>
                                    <p class="ma-0 white--text text-center">
                                        Solde :
                                    </p>
                                    <div class="white--text ma-0 text-center">
                                      {{ recettes_table.pays == 'BENIN' ? arrondi ((recettes_table.recette  - (( (recettes_table.recette - recettes_table.depense) * 0.1) + recettes_table.depense + (recettes_table.recette/2) )),2) : arrondi ((recettes_table.recette  - (( ((recettes_table.recette/2) - recettes_table.depense) * 0.1) + recettes_table.depense + (recettes_table.recette/2) )),2) }}
                                    </div>
                          </v-row>
                          </v-col>
                        </div>
                    </v-card-text>
                </base-card>
        </v-col>

        <v-col cols="12" md="4">
          <base-card color="#34495E">
          <v-card-text class="d-flex align-center h-full">
            <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
              <v-col>
                  <h3 class="text-center white--text">Total</h3>
                    <v-row>
                                    <p class="ma-0 white--text text-center">
                                      Commission :
                                    </p>
                                    <div class="white--text ma-0 text-center">
                                      {{ this.total_recettes() }}
                                    </div>
                    </v-row>

                    <v-row>
                                    <p class="ma-0 white--text text-center">
                                        Depenses :
                                    </p>
                                    <div class="white--text ma-0 text-center">
                                      {{ this.total_depenses() }}
                                    </div>
                    </v-row>

                    <v-row>
                                          <p class="ma-0 white--text text-center">
                                              Commission parrain :
                                          </p>
                                          <div class="white--text ma-0 text-center">
                                            {{ this.total_commission_parrain()  }}
                                          </div>
                    </v-row>

                    <v-row>
                                          <p class="ma-0 white--text text-center">
                                              Solde :
                                          </p>
                                          <div class="white--text ma-0 text-center">
                                            {{ this.total_solde() }}
                                          </div>
                    </v-row>

              </v-col>
            </div>
          </v-card-text>
        </base-card>
        </v-col>

      </v-row>

    </v-col>

  </v-row>

</v-col>

</template>

<script>
import CryptoCurrencyCard from '@/components/card/CryptoCurrencyCard'
import ChartCard from '@/components/card/ChartCard'
import api from '@/serviceApi/apiService'
import moment from 'moment'
import VueMomentsAgo from 'vue-moments-ago'

export default {
    name: 'Dashbord',
    components: {
    VueMomentsAgo
  },
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Dashbord'
    },
    components: {
        CryptoCurrencyCard,
        ChartCard
    },
    data() {
        return {

            date_debut: null,
            date_fin: null,

            recettes:null,
            recettes_table: [],
            items: [],
            connected_profil: false,

        }
    },
    created() {

          let today = new Date();
          let dd = today.getDate();
          let mm = today.getMonth()+1; //January is 0!
          let yyyy = today.getFullYear();
          this.date_debut = '2023-01-01';
          this.date_fin = yyyy+'-'+mm+'-'+dd;

        this.readRecettes();
        //this.readDepenses();
        //this.getConnectedProfil();


    },
    methods: {

        readRecettes: async function () {
            let fd = new FormData();
            fd.append('date_debut', this.date_debut);
            fd.append('date_fin', this.date_fin);
            const data = await api.createUpdatedata('backoffice/recettes', fd);
            //console.log('recettes',data );
            this.recettes = data;
            await this.getConnectedProfil();
        },


        getConnectedProfil: async function () {
          const profil = JSON.parse(localStorage.getItem("profil"));
          if(profil.id == 1 || profil.id == 3 || profil.id == 5){
            this.connected_profil = true;
            this.recettes_table = this.recettes.recette;
          }else{
            this.connected_profil = false;
          }
        },

        //get total recettes
        total_recettes: function () {
            let total = 0;
            for (let i = 0; i < this.recettes_table.length; i++) {
                if (this.recettes_table[i].pays == 'BENIN') {
                    total += parseInt(this.recettes_table[i].recette);
                } else {
                    total += parseInt(this.recettes_table[i].recette / 2);
                }
                //console.log(this.recettes_table[i].pays+' '+this.recettes_table[i].recette);
            }
            console.log('total recettes', total);
            return this.arrondi(total, 2);
        },

        //get total depenses
        total_depenses: function () {
            let total = 0;
            for (let i = 0; i < this.recettes_table.length; i++) {
                total += this.recettes_table[i].depense;
            }
            return this.arrondi(total, 2);
        },


        //get total commission parrain
        total_commission_parrain: function () {
            let total = 0;
            for (let i = 0; i < this.recettes_table.length; i++) {
                if (this.recettes_table[i].pays == 'BENIN') {
                    total += ((this.recettes_table[i].recette) - this.recettes_table[i].depense) * 0.1;
                } else {
                    total += ((this.recettes_table[i].recette / 2) - this.recettes_table[i].depense) * 0.1;
                }
            }
            return this.arrondi(total, 2);
        },


        //get total solde
        total_solde: function () {
            let total = 0;
            for (let i = 0; i < this.recettes_table.length; i++) {
                if (this.recettes_table[i].pays == 'BENIN') {
                    total += this.recettes_table[i].recette - (((this.recettes_table[i].recette - this.recettes_table[i].depense) * 0.1) + this.recettes_table[i].depense + (this.recettes_table[i].recette / 2));
                } else {
                    total += this.recettes_table[i].recette - ((((this.recettes_table[i].recette / 2) - this.recettes_table[i].depense) * 0.1) + this.recettes_table[i].depense + (this.recettes_table[i].recette / 2));
                }
            }
            return this.arrondi(total, 2);
        },


        //arrondi des chiffres
        arrondi: function (nombre, precision) {
            var precision = precision || 2;
            var tmp = Math.pow(10, precision);
            return Math.round(nombre * tmp) / tmp;
        },


        save_status: async function (item) {
            console.log(item.statut);
            let fd = new FormData();
            fd.append('id', item.id);
            fd.append('statut',item.statut==1?0:1 );
            const data = await api.createUpdatedata('backoffice/etat-server', fd);
            if (data.status == true) {
                this.showAlert(data.message, 'success');

            } else {
                this.showAlert(data.message, 'error');

            }
            this.readAll_serveur();
        },

        detStatus(item) {
            let time = Date.now() - parseInt(item.last_update);
            if ((item.status == 1) && (time < 90000)) {
                return true;
            } else {
                return false;
            }
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                 position: 'top-end',
                 icon: txticone,
                 title: textmessage,
                 showConfirmButton: false,
                 timer: 1500
             })
         },
         Etat_time(item){
            if(item.etat_time<90000){
                return 'red';
            }else{
                return 'green';
            }
         },
         Etat_moment(item){
            return moment(item).locale('fr').fromNow();
         },

    },

}
</script>
